.app__loginForm-main-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__loginForm-container {
  display: flex;
  width: 1000px;
  height: 600px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.app__loginForm-left-container {
  width: 55%;
}

.app__loginForm-right-container {
  width: 45%;
}

.app__loginForm-img {
  height: 600px;
  object-fit: cover;
  border-radius: 0 5px 5px 0;
}

.app__loginForm-title-container {
  display: flex;
  justify-content: center;
  margin: 40px 0 20px 0;
  color: #222;
}

.app__loginForm-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.app_loginForm-user-name {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 30px;
}
.app__loginForm-password {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 30px;
}

.app__loginForm-input {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.app__loginFrom-button {
  width: 50%;
  margin: 30px;
  padding: 15px;
  border: none;
  background-color: #00a651;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
}

.app__loginFrom-button:hover {
  background-color: #222;
  transition: 0.3s;
  cursor: pointer;
}

.app__loginForm-error {
  margin-left: 30px;
  color: red;
}

@media (max-width: 1040px) {
  .app__loginForm-container {
    width: 800px;
    height: 550px;
    margin-right: 5%;
  }

  .app__loginForm-img {
    height: 550px;
  }
}

@media (max-width: 815px) {
  .app__loginForm-container {
    width: 700px;
    height: 500px;
    margin-right: 5%;
  }

  .app__loginForm-img {
    height: 500px;
  }
  .app_loginForm-title {
    font-size: 26px;
    margin-left: 10px;
  }
}

@media (max-width: 750px) {
  .app__loginForm-container {
    width: 600px;
    height: 400px;
    margin-right: 5%;
  }

  .app__loginForm-img {
    height: 400px;
  }
  .app_loginForm-title {
    font-size: 20px;
    margin-left: 10px;
  }

  .app_loginForm-user-name {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5px;
  }
  .app__loginForm-password {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 5px;
  }
}

@media (max-width: 670px) {
  .app__loginForm-container {
    width: 450px;
    height: 300px;
    margin-right: 5%;
  }

  .app__loginForm-img {
    height: 300px;
  }

  .app__loginForm-title-container {
    display: none;
  }

  .app_loginForm-user-name {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 15px;
  }
  .app__loginForm-password {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 15px;
  }
}

@media (max-width: 495px) {
  .app__loginForm-container {
    width: 400px;
    height: 220px;
    margin-right: 5%;
  }

  .app__loginForm-img {
    height: 220px;
  }
  .app_loginForm-user-name {
    margin: 5px;
  }
  .app__loginForm-password {
    margin: 5px;
  }

  .app_loginForm-user-name label {
    font-size: 12px;
  }

  .app__loginForm-password label {
    font-size: 12px;
  }

  .app__loginForm-input {
    padding: 10px;
  }

  .app__loginFrom-button {
    width: 50%;
    margin: 15px;
    padding: 15px;
  }
}

@media (max-width: 420px) {
  .app__loginForm-container {
    width: 400px;
    height: 220px;
    margin-right: 5%;
    margin-left: 5%;
  }

  .app__loginForm-img {
    height: 220px;
  }
  .app_loginForm-user-name {
    margin: 5px;
  }
  .app__loginForm-password {
    margin: 5px;
  }

  .app_loginForm-user-name label {
    font-size: 12px;
  }

  .app__loginForm-password label {
    font-size: 12px;
  }

  .app__loginForm-input {
    padding: 10px;
  }

  .app__loginFrom-button {
    width: 70%;
    margin: 5px;
    padding: 15px;
  }
}

@media (max-width: 365px) {
  .app__loginForm-container {
    width: 300px;
    height: 220px;
    margin-right: 5%;
    margin-left: 5%;
  }

  .app__loginForm-img {
    height: 220px;
  }
  .app_loginForm-user-name {
    margin: 5px;
  }
  .app__loginForm-password {
    margin: 5px;
  }

  .app_loginForm-user-name label {
    font-size: 12px;
  }

  .app__loginForm-password label {
    font-size: 12px;
  }

  .app__loginForm-input {
    padding: 10px;
  }

  .app__loginFrom-button {
    width: 70%;
    margin: 5px;
    padding: 15px;
  }
}
