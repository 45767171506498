header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  justify-content: center;
  height: 80px;
  padding: 0 2rem;
  background-color: #fff;
  color: #222;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 999;
}

.nav-logo {
  width: 200px;
  margin-right: 200px;
}

nav a {
  margin: 0 20px;
  color: #222;
  text-decoration: none;
}

nav a:hover {
  box-shadow: 0 4px 1px -3px #00a651;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #222;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}

@media (max-width: 1220px) {
  .nav-logo {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    margin-left: 2rem;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: #00a651;
    /* transition: 1s;    */
    transform: translateY(-100vh);
    transition: 0.5s;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  nav a:hover {
    box-shadow: 0 4px 1px -3px #222;
  }

  header .nav-btn {
    padding-left: 320px;
  }
}

@media (max-width: 600px) {
  header .nav-btn {
    padding-left: 150px;
  }
}

@media (max-width: 430px) {
  header .nav-btn {
    padding-left: 10px;
  }
}
