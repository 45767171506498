.app__PageNotFound-main-content{

    background-color: #00a651;
}

.app__PageNotFound-title{
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    color: #fff;
    font-size: 36px;
    font-weight: 700;
}