.app__Component-SideBar-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ddd;
  /* background-color: #312e81; */
  background-color: #00a651;
  min-height: 400px;
  border-radius: 5px;
  /* color: #a5b4fc; */
  color: #00d166;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  top: 90px;
  position: sticky;
}

.app__Component-Sidebar-href {
  color: #00d166;
  text-decoration: none;
}

.bootstrap-icons {
  color: #00d166;
  width: 30px;
  height: 30px;
}

.app__Component-SideBar-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  /* margin-left: 10px;
    margin-top: 10px; */
  cursor: pointer;
  padding: 5px;
}

.app__Component-Sidebar-font-size {
  font-size: 16px;
  font-weight: 700;
}

.app__Component-Sidebar-hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #00d166;
  margin: 10px 0;
  padding: 0;
}

.app__Component-SideBar-wrap:hover {
  background-color: #01bb5c;
  border-radius: 5px;
}

.app__Component-Sidebar-href:hover {
  color: #fff;
}
