.styled-table {
  border-collapse: collapse;
  margin: 25px 0 0 25px;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 950px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #00a651;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #00a651;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #00a651;
}

.app__table-img {
  width: 120px;
  height: 120px;
}

.app__table-img:hover {
  transform: scale(1.2);
}

.list-delete-button {
  background-color: #dc2626;
  border: none;
  color: #fff;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.list-delete-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.list-delete-button:disabled:hover {
  background-color: #ddd;
}

.list-delete-button:hover {
  background-color: #222;
}

.list-edit-button {
  background-color: #00a651;
  border: none;
  color: #fff;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
}

.list-edit-button:hover {
  background-color: #222;
}

.main-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.app__sideBarAdmin-button-add {
  background-color: #00a651;
  padding: 20px 40px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.app__sideBarAdmin-button-add:hover {
  background-color: #222;
}

.app__List-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Toggle button */

.app__sideBarAdmin-toggle {
  -webkit-appearance: none;
  position: relative;
  width: 50px;
  height: 30px;
  border-radius: 25px;
  background-color: #00a651;
  transition: background 0.3s;
  outline: none;
  cursor: pointer;
}

.app__sideBarAdmin-toggle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  background-color: #fff;
  transition: left 0.3s;
}

.app__sideBarAdmin-toggle:checked {
  background-color: #dc2626;
  border: none;
}

.app__sideBarAdmin-toggle:checked:after {
  left: 70%;
}

.app__sideBarAdmin-toggle-title {
  font-size: 14px;
}

.app__sideBardAdmin-toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

@media (max-width: 1700px) {
  .main-container {
    width: 1000px;
  }
}
