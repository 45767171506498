.app__PanelStatystic-main-container {
  width: 100%;
  height: 70vh;
}

.app__PanelStatystic-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 1200px;
  height: auto;
}

.app__PanelStatystic-stat-container {
  width: 200px;
  height: 200px;
  background-image: linear-gradient(to right, #fff, #f8fafc);

  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__PanelStatystic-content {
  display: flex;
  flex-direction: column;
}

.app__PanelStatystic-title-count {
  display: flex;
  justify-content: center;
}

.app__PanelStatystic-container-img {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.app__PanelStatystic-img {
  width: 75px;
  height: auto;
}

@media (max-width: 1770px) {
  .app__PanelStatystic-container {
    grid-template-columns: repeat(3, 1fr);
    width: 900px;
    height: auto;
  }
}

@media (max-width: 1200px) {
  .app__PanelStatystic-container {
    grid-template-columns: repeat(2, 1fr);
    width: 800px;
    height: 1500px;
  }

  .app__PanelStatystic-stat-container {
    width: 300px;
    height: 300px;
  }

  .app__PanelStatystic-main-container {
    width: 100%;
    height: auto;
  }
}
