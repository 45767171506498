.pagination-button {
  padding: 10px;
  margin-right: 5px;
  border: none;
  background-color: #00a651;
  color: #fff;
  cursor: pointer;
}

.pagination-button:hover {
  background-color: #222;
}
