.app__contact-main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a651;
}

.app__contact-contact-container {
  width: 600px;
  height: 600px;
  background-color: #fff;
  border-radius: 20px;
}

.app__contact-contact-title {
  font-size: 36px;
  font-weight: 700;
}

.app__contact-form-container {
  display: flex;
  justify-self: center;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
}

.app__contact-input {
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.app__contact-textarea {
  width: 100%;
  height: 200px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.app__contact-button {
  width: 30%;
  height: 40px;
  margin-top: 10px;
  background-color: #00a651;
  font-weight: 400;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 5px;
}

.app__contact-button:hover {
  background-color: #222;
  cursor: pointer;
}

.app__contact-success-alert {
  margin: 10px;
  padding: 5px;
  border-bottom: 1px solid #00a651;
}
